/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  font: inherit;
  cursor: pointer;
  text-transform: none;
  color: inherit;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 15px;
  transition-property: opacity, filter;
  transition-duration: .15s;
  transition-timing-function: linear;
  display: inline-block;
  overflow: visible;
}

.hamburger.is-active:hover, .hamburger:hover {
  opacity: .7;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:after, .hamburger.is-active .hamburger-inner:before {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  margin-top: -2px;
  display: block;
  top: 50%;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  background-color: #000;
  border-radius: 4px;
  width: 40px;
  height: 4px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  position: absolute;
}

.hamburger-inner:after, .hamburger-inner:before {
  content: "";
  display: block;
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

.hamburger--3dx .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx .hamburger-inner:after, .hamburger--3dx .hamburger-inner:before {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx.is-active .hamburger-inner {
  transform: rotateY(180deg);
  background-color: #0000 !important;
}

.hamburger--3dx.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dx-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx-r .hamburger-inner:after, .hamburger--3dx-r .hamburger-inner:before {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dx-r.is-active .hamburger-inner {
  transform: rotateY(-180deg);
  background-color: #0000 !important;
}

.hamburger--3dx-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy .hamburger-inner:after, .hamburger--3dy .hamburger-inner:before {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy.is-active .hamburger-inner {
  transform: rotateX(-180deg);
  background-color: #0000 !important;
}

.hamburger--3dy.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy-r .hamburger-inner:after, .hamburger--3dy-r .hamburger-inner:before {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dy-r.is-active .hamburger-inner {
  transform: rotateX(180deg);
  background-color: #0000 !important;
}

.hamburger--3dy-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dxy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy .hamburger-inner:after, .hamburger--3dxy .hamburger-inner:before {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy.is-active .hamburger-inner {
  transform: rotateX(180deg)rotateY(180deg);
  background-color: #0000 !important;
}

.hamburger--3dxy.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--3dxy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy-r .hamburger-inner {
  transition: transform .15s cubic-bezier(.645, .045, .355, 1), background-color 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy-r .hamburger-inner:after, .hamburger--3dxy-r .hamburger-inner:before {
  transition: transform 0s cubic-bezier(.645, .045, .355, 1) .1s;
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  transform: rotateX(180deg)rotateY(180deg)rotate(-180deg);
  background-color: #0000 !important;
}

.hamburger--3dxy-r.is-active .hamburger-inner:before {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--arrow.is-active .hamburger-inner:before {
  transform: translate3d(-8px, 0, 0)rotate(-45deg)scaleX(.7);
}

.hamburger--arrow.is-active .hamburger-inner:after {
  transform: translate3d(-8px, 0, 0)rotate(45deg)scaleX(.7);
}

.hamburger--arrow-r.is-active .hamburger-inner:before {
  transform: translate3d(8px, 0, 0)rotate(45deg)scaleX(.7);
}

.hamburger--arrow-r.is-active .hamburger-inner:after {
  transform: translate3d(8px, 0, 0)rotate(-45deg)scaleX(.7);
}

.hamburger--arrowalt .hamburger-inner:before {
  transition: top .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt .hamburger-inner:after {
  transition: bottom .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner:before {
  transition: top .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  top: 0;
  transform: translate3d(-8px, -10px, 0)rotate(-45deg)scaleX(.7);
}

.hamburger--arrowalt.is-active .hamburger-inner:after {
  transition: bottom .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  bottom: 0;
  transform: translate3d(-8px, 10px, 0)rotate(45deg)scaleX(.7);
}

.hamburger--arrowalt-r .hamburger-inner:before {
  transition: top .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt-r .hamburger-inner:after {
  transition: bottom .1s .1s, transform .1s cubic-bezier(.165, .84, .44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:before {
  transition: top .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  top: 0;
  transform: translate3d(8px, -10px, 0)rotate(45deg)scaleX(.7);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:after {
  transition: bottom .1s, transform .1s cubic-bezier(.895, .03, .685, .22) .1s;
  bottom: 0;
  transform: translate3d(8px, 10px, 0)rotate(-45deg)scaleX(.7);
}

.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner:before {
  transform: translate3d(8px, 0, 0)rotate(45deg)scaleX(.7);
}

.hamburger--arrowturn.is-active .hamburger-inner:after {
  transform: translate3d(8px, 0, 0)rotate(-45deg)scaleX(.7);
}

.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner:before {
  transform: translate3d(-8px, 0, 0)rotate(-45deg)scaleX(.7);
}

.hamburger--arrowturn-r.is-active .hamburger-inner:after {
  transform: translate3d(-8px, 0, 0)rotate(45deg)scaleX(.7);
}

.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner:after, .hamburger--boring .hamburger-inner:before {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner:before {
  opacity: 0;
  top: 0;
}

.hamburger--boring.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(-90deg);
}

.hamburger--collapse .hamburger-inner {
  transition-duration: .13s;
  transition-delay: .13s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  top: auto;
  bottom: 0;
}

.hamburger--collapse .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity .1s linear;
  top: -20px;
}

.hamburger--collapse .hamburger-inner:before {
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--collapse.is-active .hamburger-inner:after {
  opacity: 0;
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .22s;
  top: 0;
}

.hamburger--collapse.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .16s, transform .13s cubic-bezier(.215, .61, .355, 1) .25s;
  top: 0;
  transform: rotate(-90deg);
}

.hamburger--collapse-r .hamburger-inner {
  transition-duration: .13s;
  transition-delay: .13s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  top: auto;
  bottom: 0;
}

.hamburger--collapse-r .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity .1s linear;
  top: -20px;
}

.hamburger--collapse-r .hamburger-inner:before {
  transition: top .12s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: translate3d(0, -10px, 0)rotate(45deg);
}

.hamburger--collapse-r.is-active .hamburger-inner:after {
  opacity: 0;
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s linear .22s;
  top: 0;
}

.hamburger--collapse-r.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .16s, transform .13s cubic-bezier(.215, .61, .355, 1) .25s;
  top: 0;
  transform: rotate(90deg);
}

.hamburger--elastic .hamburger-inner {
  transition-duration: .275s;
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  top: 2px;
}

.hamburger--elastic .hamburger-inner:before {
  transition: opacity .125s .275s;
  top: 10px;
}

.hamburger--elastic .hamburger-inner:after {
  transition: transform .275s cubic-bezier(.68, -.55, .265, 1.55);
  top: 20px;
}

.hamburger--elastic.is-active .hamburger-inner {
  transition-delay: 75ms;
  transform: translate3d(0, 10px, 0)rotate(135deg);
}

.hamburger--elastic.is-active .hamburger-inner:before {
  opacity: 0;
  transition-delay: 0s;
}

.hamburger--elastic.is-active .hamburger-inner:after {
  transition-delay: 75ms;
  transform: translate3d(0, -20px, 0)rotate(-270deg);
}

.hamburger--elastic-r .hamburger-inner {
  transition-duration: .275s;
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  top: 2px;
}

.hamburger--elastic-r .hamburger-inner:before {
  transition: opacity .125s .275s;
  top: 10px;
}

.hamburger--elastic-r .hamburger-inner:after {
  transition: transform .275s cubic-bezier(.68, -.55, .265, 1.55);
  top: 20px;
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transition-delay: 75ms;
  transform: translate3d(0, 10px, 0)rotate(-135deg);
}

.hamburger--elastic-r.is-active .hamburger-inner:before {
  opacity: 0;
  transition-delay: 0s;
}

.hamburger--elastic-r.is-active .hamburger-inner:after {
  transition-delay: 75ms;
  transform: translate3d(0, -20px, 0)rotate(270deg);
}

.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color .125s ease-in .175s;
}

.hamburger--emphatic .hamburger-inner:before {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, left .125s ease-in .175s;
  left: 0;
}

.hamburger--emphatic .hamburger-inner:after {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, right .125s ease-in .175s;
  top: 10px;
  right: 0;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: #0000 !important;
}

.hamburger--emphatic.is-active .hamburger-inner:before {
  transition: left .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: -80px;
  left: -80px;
  transform: translate3d(80px, 80px, 0)rotate(45deg);
}

.hamburger--emphatic.is-active .hamburger-inner:after {
  transition: right .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: -80px;
  right: -80px;
  transform: translate3d(-80px, 80px, 0)rotate(-45deg);
}

.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color .125s ease-in .175s;
}

.hamburger--emphatic-r .hamburger-inner:before {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, left .125s ease-in .175s;
  left: 0;
}

.hamburger--emphatic-r .hamburger-inner:after {
  transition: transform .125s cubic-bezier(.6, .04, .98, .335), top 50ms linear .125s, right .125s ease-in .175s;
  top: 10px;
  right: 0;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: #0000 !important;
}

.hamburger--emphatic-r.is-active .hamburger-inner:before {
  transition: left .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: 80px;
  left: -80px;
  transform: translate3d(80px, -80px, 0)rotate(-45deg);
}

.hamburger--emphatic-r.is-active .hamburger-inner:after {
  transition: right .125s ease-out, top 50ms linear .125s, transform .125s cubic-bezier(.075, .82, .165, 1) .175s;
  top: 80px;
  right: -80px;
  transform: translate3d(-80px, -80px, 0)rotate(45deg);
}

.hamburger--minus .hamburger-inner:after, .hamburger--minus .hamburger-inner:before {
  transition: bottom 80ms ease-out, top 80ms ease-out, opacity linear;
}

.hamburger--minus.is-active .hamburger-inner:after, .hamburger--minus.is-active .hamburger-inner:before {
  opacity: 0;
  transition: bottom 80ms ease-out, top 80ms ease-out, opacity 0s linear 80ms;
}

.hamburger--minus.is-active .hamburger-inner:before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner:after {
  bottom: 0;
}

.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner:before {
  transition-property: transform, opacity;
  transition-duration: .15s;
  transition-timing-function: ease;
  top: 10px;
}

.hamburger--slider .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner:before {
  opacity: 0;
  transform: rotate(-45deg)translate3d(-5.71429px, -6px, 0);
}

.hamburger--slider.is-active .hamburger-inner:after {
  transform: translate3d(0, -20px, 0)rotate(-90deg);
}

.hamburger--slider-r .hamburger-inner {
  top: 2px;
}

.hamburger--slider-r .hamburger-inner:before {
  transition-property: transform, opacity;
  transition-duration: .15s;
  transition-timing-function: ease;
  top: 10px;
}

.hamburger--slider-r .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0)rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner:before {
  opacity: 0;
  transform: rotate(45deg)translate3d(5.71429px, -6px, 0);
}

.hamburger--slider-r.is-active .hamburger-inner:after {
  transform: translate3d(0, -20px, 0)rotate(90deg);
}

.hamburger--spin .hamburger-inner {
  transition-duration: .22s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin .hamburger-inner:before {
  transition: top .1s ease-in .25s, opacity .1s ease-in;
}

.hamburger--spin .hamburger-inner:after {
  transition: bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(225deg);
}

.hamburger--spin.is-active .hamburger-inner:before {
  opacity: 0;
  transition: top .1s ease-out, opacity .1s ease-out .12s;
  top: 0;
}

.hamburger--spin.is-active .hamburger-inner:after {
  transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
  bottom: 0;
  transform: rotate(-90deg);
}

.hamburger--spin-r .hamburger-inner {
  transition-duration: .22s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin-r .hamburger-inner:before {
  transition: top .1s ease-in .25s, opacity .1s ease-in;
}

.hamburger--spin-r .hamburger-inner:after {
  transition: bottom .1s ease-in .25s, transform .22s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(-225deg);
}

.hamburger--spin-r.is-active .hamburger-inner:before {
  opacity: 0;
  transition: top .1s ease-out, opacity .1s ease-out .12s;
  top: 0;
}

.hamburger--spin-r.is-active .hamburger-inner:after {
  transition: bottom .1s ease-out, transform .22s cubic-bezier(.215, .61, .355, 1) .12s;
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger--spring .hamburger-inner {
  transition: background-color 0s linear .13s;
  top: 2px;
}

.hamburger--spring .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
  top: 10px;
}

.hamburger--spring .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
  top: 20px;
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: .22s;
  background-color: #0000 !important;
}

.hamburger--spring.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
  top: 0;
  transform: translate3d(0, 10px, 0)rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
  top: 0;
  transform: translate3d(0, 10px, 0)rotate(-45deg);
}

.hamburger--spring-r .hamburger-inner {
  transition-duration: .13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  top: auto;
  bottom: 0;
}

.hamburger--spring-r .hamburger-inner:after {
  transition: top .2s cubic-bezier(.33333, .66667, .66667, 1) .2s, opacity linear;
  top: -20px;
}

.hamburger--spring-r .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, .66667, .66667, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transition-delay: .22s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: translate3d(0, -10px, 0)rotate(-45deg);
}

.hamburger--spring-r.is-active .hamburger-inner:after {
  opacity: 0;
  transition: top .2s cubic-bezier(.33333, 0, .66667, .33333), opacity 0s linear .22s;
  top: 0;
}

.hamburger--spring-r.is-active .hamburger-inner:before {
  transition: top .1s cubic-bezier(.33333, 0, .66667, .33333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
  top: 0;
  transform: rotate(90deg);
}

.hamburger--stand .hamburger-inner {
  transition: transform 75ms cubic-bezier(.55, .055, .675, .19) .15s, background-color 0s linear 75ms;
}

.hamburger--stand .hamburger-inner:before {
  transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand .hamburger-inner:after {
  transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand.is-active .hamburger-inner {
  transition: transform 75ms cubic-bezier(.215, .61, .355, 1), background-color 0s linear .15s;
  transform: rotate(90deg);
  background-color: #0000 !important;
}

.hamburger--stand.is-active .hamburger-inner:before {
  transition: top 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  top: 0;
  transform: rotate(-45deg);
}

.hamburger--stand.is-active .hamburger-inner:after {
  transition: bottom 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  bottom: 0;
  transform: rotate(45deg);
}

.hamburger--stand-r .hamburger-inner {
  transition: transform 75ms cubic-bezier(.55, .055, .675, .19) .15s, background-color 0s linear 75ms;
}

.hamburger--stand-r .hamburger-inner:before {
  transition: top 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand-r .hamburger-inner:after {
  transition: bottom 75ms ease-in 75ms, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transition: transform 75ms cubic-bezier(.215, .61, .355, 1), background-color 0s linear .15s;
  transform: rotate(-90deg);
  background-color: #0000 !important;
}

.hamburger--stand-r.is-active .hamburger-inner:before {
  transition: top 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  top: 0;
  transform: rotate(-45deg);
}

.hamburger--stand-r.is-active .hamburger-inner:after {
  transition: bottom 75ms ease-out .1s, transform 75ms cubic-bezier(.215, .61, .355, 1) .15s;
  bottom: 0;
  transform: rotate(45deg);
}

.hamburger--squeeze .hamburger-inner {
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

.hamburger--squeeze .hamburger-inner:before {
  transition: top 75ms .12s, opacity 75ms;
}

.hamburger--squeeze .hamburger-inner:after {
  transition: bottom 75ms .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transform: rotate(45deg);
}

.hamburger--squeeze.is-active .hamburger-inner:before {
  opacity: 0;
  transition: top 75ms, opacity 75ms .12s;
  top: 0;
}

.hamburger--squeeze.is-active .hamburger-inner:after {
  transition: bottom 75ms, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
  bottom: 0;
  transform: rotate(-90deg);
}

.hamburger--vortex .hamburger-inner {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.hamburger--vortex .hamburger-inner:after, .hamburger--vortex .hamburger-inner:before {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner:before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner:after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transform: rotate(765deg);
}

.hamburger--vortex.is-active .hamburger-inner:after, .hamburger--vortex.is-active .hamburger-inner:before {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner:before {
  opacity: 0;
  top: 0;
}

.hamburger--vortex.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger--vortex-r .hamburger-inner {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.hamburger--vortex-r .hamburger-inner:after, .hamburger--vortex-r .hamburger-inner:before {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner:before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner:after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transform: rotate(-765deg);
}

.hamburger--vortex-r.is-active .hamburger-inner:after, .hamburger--vortex-r.is-active .hamburger-inner:before {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner:before {
  opacity: 0;
  top: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(-90deg);
}
/*# sourceMappingURL=index.39282753.css.map */
